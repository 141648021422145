<template>
	<div id="Reference">
		<!-- 报价蜘蛛 -->
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'Reference',
		components: {},
		data() {
			return {}
		},
		created() {},
		mounted() {},
		methods: {}
	}
</script>

<style lang="less" scoped>

</style>